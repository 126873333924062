import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Layout, Header, Services, Team, Blogs, Contact, TrustedBy, Career } from '../components';

export default ({ location }) => {
	const [ active, setActive ] = useState('');
	useEffect(() => {
		setActive(location.pathname.concat(location.hash));
	}, []);
	useEffect(
		() => {
			setActive(location.pathname.concat(location.hash));
		},
		[ location.href ]
	);

	const data = useStaticQuery(graphql`
		query {
			allContentfulBlogPost {
				edges {
					node {
						title
						slug
						imageUrl {
							file {
								url
								fileName
							}
						}
						publishedDate(formatString: "MMM Do, YYYY")
					}
				}
			}
		}
	`);
	const blogsData = data.allContentfulBlogPost.edges
		.map(({ node: { slug, title, imageUrl, publishedDate } }) => ({
			title,
			imageURL: imageUrl?.file?.url,
			imageName: imageUrl?.file?.fileName,
			blogURL: `/blogs/${slug}`,
			publishedDate,
			body: ''
		}))
		.slice(0, 3);
	return (
		<Layout active={active} setActive={setActive} location={location}>
			<Header active={active} setActive={setActive} />
			<Services active={active} setActive={setActive} />
			{/* <TrustedBy /> */}
			<Team active={active} setActive={setActive} />
			<Blogs active={active} setActive={setActive} blogsData={blogsData} location={location} />
			<Career active={active} setActive={setActive} />
			<Contact active={active} setActive={setActive} />
		</Layout>
	);
};
